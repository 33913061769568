<template>
    <div class="row">
        <div class="col-12" >
            <g-loading v-if="firstLoading" />
            <g-table v-else :filter="filterFormModel" :itemCount="itemCount" :placeholder="'Nhập tên tổ chức...'" :items="items" :fields="fields" @load="load" @reset="reset"
                     title="Hội nhóm, câu lạc bộ" >
                <template slot="actionBefore">
                </template>
                <template slot="filter">
                    <client-select v-if='isPrime() || isTW() ||  isUsaid()' attribute="client_id"  :model="filterFormModel" :errors="formErrors"  :filter="{parent_id: $store.state.client.id}" :is-filter='true' :placeHolder="isPrime() ? 'Chọn đơn vị thầu phụ' : 'Chọn đơn vị thầu chính'" class="mr-2" @change='searchClient' />
                    <client-select v-if='(isTW() ||  isUsaid()) && filterFormModel.client_id' attribute="client_sub_id"  :model="filterFormModel" :errors="formErrors" placeHolder="Chọn đơn vị thầu phụ"  :filter="{parent_id: filterFormModel.client_id}" :is-filter='true' class="mr-2" @change='search' />
                    <form-select class="mr-2" :options="$params.orgCso.typeCsoOptions" :model="filterFormModel"  attribute="type" :is-filter='true'  @change="search" />
                    <province-select :model="filterFormModel" attribute="province_id"  :is-filter='true'  class="mr-2"  @change='search' />
                </template>
                <template slot="action">
                    <export :filter="filterFormModel" :params='params' :prime="isPrime() || isTW() || isUsaid()" :totalCount="itemCount" class="mr-1"/>
                    <g-button class="ml-1 btn btn-gray btn-sm" icon="fa fa-file-excel" v-if="isSub()" :loading="saveLoading" size="sm" variant="success" content="Tải xuống tập tin mẫu" @click="downloadTemplate()" />
                    <import v-if="permissions('patient_create') && isSub()"  :params='params'  class="mr-1"/>
                    <g-button class="ml-2" v-if="permissions('patient_create') && isSub()" :loading="saveLoading" size="sm" variant="success" icon="plus" content="Thêm mới" @click='create' />
                </template>
                <template #cell(name)="{item}">
                    <div ><span class="small text-gray-999">Tên tổ chức: </span>{{item.name}}</div>
                    <div ><span class="small text-gray-999">Địa chỉ: </span>
                        <span v-if='params.wards[item.ward_id]' >{{params.wards[item.ward_id]}}, </span>
                        <span v-if='params.districts[item.district_id]' >{{params.districts[item.district_id]}}, </span>
                        <span v-if='params.provinces[item.province_id]' >{{params.provinces[item.province_id]}}</span>
                    </div>
                    <div v-if='item.founded_year' ><span class="small text-gray-999">Năm thành lập: </span><span>{{item.founded_year}}</span></div>
                </template>
                <template #cell(type)="{item}">
                    <div v-if="item.type" >
                        <span>
                            {{$params.orgCso.typeCsoOptions[item.type].text}}
                        </span>
                    </div>
                </template>
                <template #cell(time)="{item}">
                    <div v-if="params.stages[item.id]">
                        <div v-for="(item1, index) in params.stages[item.id]" :key="index" >
                            <i>- Năm: </i><span class='text-danger'>{{ item1.start_time }}</span>
                            <span v-if="item1.activitys != ''"><i> tham gia hoạt động: </i><span>
                                <span v-for="activity in item1.activitys" :key="activity" class="badge bg-gray ml-1" >
                                    <span v-if="$params.orgCso.activityOptions[activity]">
                                        {{$params.orgCso.activityOptions[activity].text}}
                                    </span>
                                </span>
                            </span>
                        </span>
                        </div>
                    </div>
                </template>
                <template #cell(client_id)="{item}">
                    <span v-if='params.clients[item.client_id]' >{{params.clients[item.client_id].short_name}}</span>
                </template>
                <template #cell(action)="{item}">
                    <g-button v-if="permissions('org-cso_view')" :loading="updateLoading" size="sm" variant="outline-gray" class="ml-1 mb-1" icon="fa fa-eye" @click="view(item.id)" />
                    <g-button v-if="permissions('org-cso_update') && isSub()" :loading="updateLoading" size="sm" variant="outline-gray" class="ml-1 mb-1" icon="pencil"   @click="update(item.id)" />
                    <g-button  v-if="permissions('org-cso_del') && isSub()" :loading="delLoading" size="sm" variant="outline-danger" class="ml-1 mb-1" icon="trash-fill" @click='del(item.id)' />
                    <log type="cso" :targetId="item.id" title="Lịch sử" :isStaff="true" class="ml-1 mb-1" ></log>
                </template>
            </g-table>
        </div>
        <b-modal v-model="showForm" size='xl' :title="formTitle" @ok="save" ok-title="Lưu lại" cancel-title="Huỷ" no-close-on-backdrop no-enforce-focus>
            <div class='row'>
                <div class='col-12'>
                    <div class='row'>
                        <div class='col-md'>
                            <form-input label="Tên đầy đủ tổ chức" :required='true' :model="formModel" attribute="name"  :errors="formErrors" ></form-input>
                        </div>
                        <div class='col-md'>
                            <form-select label="Loại hình" :required='true' :model="formModel" attribute="type" :errors="formErrors" :options="$params.orgCso.typeCsoOptions" />
                        </div>
                        <div class='col-md'>
                            <form-input label="Năm thành lập" :required='true' type="number" :model="formModel" attribute="founded_year"  :errors="formErrors" placeholder="YYYY"></form-input>
                        </div>
                    </div> 
                    <div class='row'>
                        <div class='col-md'>
                            <form-group-address class="pr-1" :model="formModel" :errors="formErrors" :provinceRequired='true' :districtRequired='true' :wardRequired='true'></form-group-address>
                        </div>
                    </div>
                    <org-cso-stage label="Giai đoạn" :showFormView='showFormView' :model="formModel" :errors="formErrors" attribute="items" />
                </div> 
            </div>
            <template v-slot:modal-footer="{ ok, cancel }">
                <b-button variant="secondary" class="float-right" @click="cancel">Đóng</b-button>
                <b-button :disabled="saveLoading" variant="primary" class="float-right" @click="ok">
                    <b-spinner v-if="saveLoading" small></b-spinner>
                    <em v-else class="fa fa-save" ></em>
                    Lưu lại
                </b-button>
            </template>
        </b-modal>
        <b-modal v-model="showFormView" size='lg' :title="formTitle"   cancel-title="Huỷ" no-close-on-backdrop no-enforce-focus>
            <div class='row'>
                <div class='col-12'>
                    <div class='row'>
                        <div class='col-md'>
                            <form-input label="Tên đầy đủ tổ chức" disabled :required='true' :model="formModel" attribute="name"  :errors="formErrors" ></form-input>
                        </div>
                        <div class='col-md'>
                            <form-select label="Loại hình" disabled :required='true' :model="formModel" attribute="type" :errors="formErrors" :options="$params.orgCso.typeCsoOptions" />
                        </div>
                        <div class='col-md'>
                            <form-input label="Năm thành lập" disabled type="number" :model="formModel" attribute="founded_year"  :errors="formErrors" placeholder="YYYY"></form-input>
                        </div>
                    </div> 
                    <div class='row'>
                        <div class='col-md'>
                            <form-group-address class="pr-1" :model="formModel" :errors="formErrors" :provinceDisabled='true' :districtDisabled='true' :wardDisabled="true" :provinceRequired='true' :districtRequired='true' :wardRequired='true'></form-group-address>
                        </div>
                    </div>
                    <org-cso-stage label="Giai đoạn" :model="formModel" :showFormView='showFormView' :errors="formErrors" attribute="items" />
                </div> 
            </div>
            <template v-slot:modal-footer="{ cancel }">
                <b-button variant="secondary" class="float-right" @click="cancel">Đóng</b-button>
            </template>
        </b-modal>
    </div>
</template>

<script>
    import list from "@/mixins/List";
    import crud from '@/mixins/CRUD';
    import role from '@/mixins/Role';
    import ClientSelect from '@/components/ClientSelect';
    import Log from "@/components/Log";
    import Export from './OrgCsoExport';
    import Import from './OrgCsoImport';
    import OrgCsoStage from './OrgCsoStage';
    import ProvinceSelect from '@/components/ProvinceSelect'; 
    import ImportMinxi from "@/mixins/Template";

    export default {
        components: {ClientSelect, Log, Export, Import, OrgCsoStage, ProvinceSelect},
        mixins: [list, crud, role, ImportMinxi],
        data: function () {
            return {
                service: '/org-cso',
                reloadBus: "reloadOrgCso",
                defaultFilterFormModel: {
                    parameters: [
                        this.$params.const.race.key,
                    ],
                    keyword: '',
                    type: null,
                    client_sub_id: null,
                    client_id: this.isPrime() || this.isTW() || this.isUsaid() ? '' : this.$store.state.client.id,
                },
                defaultFormModel: {
                    type: null,
                    name: null,
                    items: [],
                    province_id: null,
                    district_id: null,
                    ward_id: null,
                },
                showFormView: false,
                //template mau
                templateName: 'to_chuc_xa_hoi.xlsx',
                headers: [
                    {header: 'name', key: 'name', label: 'Tên đầy đủ tổ chức*', width: 35, desc: "Nhập định dạng chữ (Theo Tên Đăng ký chính thức)"},
                    {header: 'type', key: 'type', label: 'Loại hình*', width: 25, desc: "Nhập 01 giá trị số từ 1 đến 4 (Trong đó: 1. Nhóm nữ quyền/2. Nhóm dân tộc thiểu số/3. Hội nhóm, tổ chức người khuyết tật/4. Nhóm tự do tôn giáo)"},
                    {header: 'founded_year', key: 'founded_year', label: 'Năm thành lập*', width: 20, desc: "Nhập định dạng năm (yyyy)"},
                    {header: 'province_id', key: 'province_id', label: 'Tỉnh/Thành phố*', width: 35, desc: "Nhập định dạng chữ (" + this.$params.provinceExport.filter(item => item.value != null).map(item => item.text).toString() + ")"},
                    {header: 'district_id', key: 'district_id', label: 'Quận/Huyện*', width: 35, desc: "Nhập định dạng chữ"},
                    {header: 'ward_id', key: 'ward_id', label: 'Xã/Phường*', width: 35, desc: "Nhập định dạng chữ"},
                    {header: 'start_time', key: 'start_time', label: 'Thời gian tham gia vận động chính sách', width: 25, desc: "Nhập định dạng năm. Thời gian phải trước hoặc bằng năm nhập dữ liệu (yyyy)"},
                    {header: 'activity', key: 'activity', label: 'Hoạt động đã tham gia*', width: 25, desc: "Nhập 01 hoặc nhiều giá trị số từ 1 đến 4 (Trong đó: " + this.$params.orgCso.activityOptions.filter(item => item.value != null).map(item => item.text).toString() + ")"},
                ],
            };
        },
        computed: {
            fields() {
                let fields = [
                    {key: 'name', label: "Tên tổ chức"},
                    {key: 'type', label: "Loại hình"},
                    {key: 'time', label: "Giai đoạn tham gia vận động chính sách"},
                    {key: 'action', label: "Hành động", class: 'text-right'},
                ];
                if (this.isPrime() || this.isTW() || this.isUsaid()) {
                    return [{key: 'client_id', label: "Đơn vị"}].concat(fields);
                }
                return fields;
            },
        },
        watch: {
        },
        methods: {
            searchClient() {
                this.filterFormModel.client_sub_id = null;
                this.filterFormModel.page = 1;
                this.load();
            },
            view: function (id) {
                let uri = this.service;
                if (id) {
                    uri += '/get?id=' + id;
                }
                this.updateLoading = true;
                this.$service.get(uri).then(response => {
                    this.formModel = {};
                    this.formErrors = {};
                    this.showFormView = true;
                    this.formModel = response.data;
                    this.formTitle = "Xem thông tin Tổ chức xã hội: " + this.formModel.name;
                    this.formModel.id = id;
                }).finally(() => {
                    this.updateLoading = false;
                });
            },
        }
    }
</script>