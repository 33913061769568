<template>
    <button class="btn btn-gray btn-sm mr-1 ml-1" @click="exportFile">
        <i class="fa fa-download icon-left"></i> Xuất dữ liệu
        <b-modal v-model="exporting" title="Đang tải dữ liệu" @close="close" no-close-on-esc no-close-on-backdrop no-enforce-focus>
            <div class="alert alert-warning">
                <b-spinner small variant="primary"></b-spinner>&nbsp;
                <span>Dữ liệu đang được tải xuống! Xin vui lòng đợi trong giây lát và xin đừng tắt cửa sổ này cho đến khi quá trình tải xuống hoàn tất.</span>
                {{downloadedCount}} - {{totalCount}}
                <b-progress :max="totalCount">
                    <b-progress-bar :value="downloadedCount" :label="`${((downloadedCount / totalCount) * 100).toFixed(0)}%`"></b-progress-bar>
                </b-progress>
            </div>
        </b-modal>
    </button>
</template>

<script>
    import Export from "@/mixins/Export";
    export default {
        props: ['filter', 'totalCount', 'prime', 'params'],
        mixins: [Export],
        data() {
                let headers = [
                    {header: 'name', key: 'name', label: 'Tên đầy đủ tổ chức', width: 30},
                    {header: 'type', key: 'type', label: 'Loại hình', width: 40},
                    {header: 'founded_year', key: 'founded_year', label: 'Năm thành lập', width: 20},
                    {header: 'province_id', key: 'province_id', label: 'Tỉnh/Thành phố', width: 35},
                    {header: 'district_id', key: 'district_id', label: 'Quận/Huyện', width: 35},
                    {header: 'ward_id', key: 'ward_id', label: 'Xã/Phường', width: 35},
                    {header: 'start_time', key: 'start_time', label: 'Thời gian tham gia vận động chính sách', width: 40},
                    {header: 'activity', key: 'activity', label: 'Hoạt động đã tham gia', width: 40},
                ];
                if (this.prime) {
                    headers.unshift({header: 'client_id', key: 'client_id', label: 'Đơn vị', width: 20});
                }
            return {
                service: '/org-cso',
                fileName: `to_chuc_xa_hoi`,
                headers,
            }
        },
        methods: {
            setupSheet: function (sheet) {
                let cols = [];
                let row = {};
                for (let i in this.headers) {
                    let header = this.headers[i];
                    cols.push(header);
                    row[header.key] = header.label;
                }
                sheet.columns = cols;
                sheet.addRow(row);

                let provinces = [];
                for (const [value] of Object.entries(this.params.provinceExport)) {
                    provinces.push(this.params.provinceExport[value]);
                }
                let activitys = []
                for (const [key, value] of Object.entries(this.$params.orgCso.activityOptions)) {
                    if(key != null && value.value != null) {
                        activitys.push(value.text);
                    }
                }
                row = {};
                if(this.prime){
                    row['client_id'] = "Nhập định dạng chữ";
                }
                row["name"] = "Nhập định dạng chữ (Theo Tên Đăng ký chính thức)";
                row["type"] = "Nhập 01 giá trị số từ 1 đến 4 (Trong đó: 1. Nhóm nữ quyền/2. Nhóm dân tộc thiểu số/3. Hội nhóm, tổ chức người khuyết tật/4. Nhóm tự do tôn giáo)";
                row["founded_year"] = "Nhập định dạng năm (yyyy)";
                row["province_id"] = "Nhập định dạng chữ (" + provinces.toString() + ")";
                row["district_id"] = "Nhập định dạng chữ";
                row["ward_id"] = "Nhập định dạng chữ";
                row["start_time"] = "Nhập định dạng năm. Thời gian phải trước hoặc bằng năm nhập dữ liệu (yyyy)";
                row["activity"] = "Nhập 01 hoặc nhiều giá trị số từ 1 đến 4 (Trong đó: " + activitys.toString() + ")";
                sheet.addRow(row);
                row.alignment = {wrapText: true};
                //ẩn dòng 1
                sheet.getRow(1).hidden = true;
                sheet.getRow(1).alignment = {vertical: 'middle', horizontal: 'center', wrapText: true};
                sheet.getRow(1).fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: {
                        argb: 'bdbdbd'
                    }
                };

                // đôi đậm và wrap text
                sheet.getRow(2).alignment = {vertical: 'middle', horizontal: 'center', wrapText: true};
                sheet.getRow(2).font = {
                    bold: true
                };
                //bôi màu
                sheet.getRow(2).fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: {
                        argb: '92D050'
                    }
                };
                //Dòng 3
                sheet.getRow(3).alignment = {vertical: 'middle', horizontal: 'center', wrapText: true};
                sheet.getRow(3).font = {
                    italic: true
                };


            },
            addRow(item, params) {
                let row = {
                        name: item.name,
                        type: item.type && this.$params.orgCso.typeCsoOptions.find(o => o.value == item.type) ? this.$params.orgCso.typeCsoOptions.find(o => o.value == item.type).text : null,
                        founded_year : item.founded_year,
                        province_id: params.provinces && params.provinces[item.province_id] ? params.provinces[item.province_id] : '',
                        district_id: params.districts && params.districts[item.district_id] ? params.districts[item.district_id] : '',
                        ward_id: params.wards && params.wards[item.ward_id] ? params.wards[item.ward_id] : '',
                    };
                if(this.prime){
                    row['client_id'] = params.clients[item.client_id].short_name;
                }
                if(params.stages[item.id].length > 0){
                    row['start_time'] = params.stages[item.id][0].start_time;
                    row['activity'] = this.getActivity(params.stages[item.id][0]);
                }
                return row;
            },
            getActivity(data) {
                let text = "";
                if (data.activitys) {
                    for (let ac of data.activitys) {
                        if(this.$params.orgCso.activityOptions.find(o => o.value == ac)){
                            text = text + this.$params.orgCso.activityOptions.find(o => o.value == ac).text + "; ";
                        }
                    }
                }
                return text.substring(0, text.length - 2);
            },
        }
    }
</script>